import React from 'react'
import Privacy from '../components/Privacy'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

function privacy() {
  return (
    <Layout border='2px solid #424242' lang='fr-fr'>
        <Seo title='Privacy Policy | Shukran' descriptionEn='Privacy Policy'  descriptionFr='Politique de confidentialité'/>
        <Privacy fr={true}/>
    </Layout>
  )
}

export default privacy